import {
    CallToAction as _CallToAction,
    ReportHighlight as _ReportHighlight,
} from "@2po-dpam/components";
import { Image } from "@components";
import { ContentfulImageData } from "@components/Image";
import { useContextStore, useRouting } from "@hooks";
import { ILink } from "@types";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { getFixedUrlIfMarketAndLanguageDoesNotExist } from "@utils/url";

type ReportHighlightData = {
    document: {
        title: string;
        slug: string;
    };
    node_locale: string;
    cardTitle?: string | undefined;
    ctaLabel: string;
    image: ContentfulImageData;
    internalLink?: ILink | undefined;
    externalLink?: string | undefined;
};

type Props = {
    data: ReportHighlightData;
};

const ReportHighlight = ({
    data: {
        document,
        ctaLabel,
        image,
        node_locale,
        cardTitle,
        internalLink,
        externalLink,
    },
}: Props) => {
    const locale = node_locale.replace(/-./g, x => x[1].toUpperCase());
    const documentUrl = `/documents/${document?.slug}-${locale}`;
    const isDocument = document?.slug ? true : false;

    const [hrefToUse, setHrefToUse] = useState<string | undefined>(undefined);
    const { language, market } = useContextStore();
    const { getPathForSlug } = useRouting();

    useEffect(() => {
        const link = internalLink?.pageLink?.slug
            ? getPathForSlug(internalLink.pageLink.slug)
            : externalLink;

        if (link && language) {
            setHrefToUse(
                getFixedUrlIfMarketAndLanguageDoesNotExist(
                    link,
                    language,
                    market,
                ),
            );
        }
    }, [internalLink, externalLink, language, market]);

    return (
        <_ReportHighlight
            cta={
                ctaLabel ? (
                    <_CallToAction
                        href={isDocument ? documentUrl : hrefToUse}
                        openInNewTab={isDocument}
                    >
                        {ctaLabel}
                    </_CallToAction>
                ) : null
            }
            image={
                image?.image ? (
                    <Image
                        {...image}
                        alt={image?.alt || "Report image"}
                        caption={undefined}
                        title={undefined}
                    />
                ) : null
            }
            title={cardTitle || document?.title}
        />
    );
};

export default ReportHighlight;

export const query = graphql`
    fragment ReportHighlight on ContentfulAssemblyHighlightedReport {
        id
        contentful_id
        __typename
        node_locale
        document {
            title
            slug
        }
        cardTitle: title
        externalLink
        internalLink {
            ...NavLink
        }
        image {
            ...ImageFullWidth
        }
        ctaLabel
    }
`;
