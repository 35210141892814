import { getInvestorSpecificContent } from "@utils/investors";
import { mapToMarketsAndLanguages } from "@utils/marketLanguages";
import { navigate } from "gatsby";

import useAvailablePageLocales from "./use-available-page-locales";
import useContextStore from "./use-context-store";
import usePageContext from "./use-page-context";

type PathProps = {
    language?: string;
    market?: string;
    investor?: string;
};

type RoutingHelpers = {
    getPath: (x: PathProps) => string;
    getPathForSlug: (s: string) => string;
    getAlternateLanguagePaths: () => any;
    redirectIfPageDisabled: () => void;
    getHomePath: (x: PathProps) => string;
};

const useRouting = (): RoutingHelpers => {
    const contextStore = useContextStore();
    const { language, market, investor } = contextStore;
    const { slugs } = useAvailablePageLocales();
    const { pages } = usePageContext();
    const { marketLanguages } = usePageContext();
    const basePath = `/${investor}/${market}/${language}`;

    const getPath = (pathPara: PathProps) => {
        const defaultPara = contextStore;
        const { language, market, investor } = { ...defaultPara, ...pathPara };
        const localeKey = `${language}-${market}`;
        const slug = slugs[localeKey];

        if (slug === "home") return getHomePath(pathPara);
        return `/${investor}/${market}/${language}/${slug}`;
    };

    const getInvestorSpecificSlug = (slug: string) => {
        const page = pages.find(({ slug: pageSlug }) => slug === pageSlug);
        if (page) {
            const content = getInvestorSpecificContent(investor, page.pageType);
            if (content) return slug;
        }
        return "";
    };
    const getHomePath = (pathProps: PathProps) => {
        const { language, market, investor } = {
            ...contextStore,
            ...pathProps,
        };
        return `/${investor}/${market}/${language}`;
    };

    const getPathForSlug = (slug: string) => {
        if (!slug || slug === "home") return basePath;
        return `${basePath}/${getInvestorSpecificSlug(slug)}`;
    };

    const getAlternateLanguagePaths = () => {
        const map =
            marketLanguages && mapToMarketsAndLanguages(marketLanguages);
        if (!map) return {};

        const availableLanguages = map.get(market);

        if (!availableLanguages) return {};
        const defaultLanguage = availableLanguages.includes("en")
            ? "en"
            : availableLanguages[0];
        const hreflangBase = {
            "x-default": getPath({ language: defaultLanguage }),
        };

        return availableLanguages.reduce((object, language) => {
            object[language] = getPath({ language });
            return object;
        }, hreflangBase);
    };

    const redirectIfPageDisabled = () => {
        if (typeof window === "undefined") return;
        const { disabledInvestorTypes, fallbackSlug } = contextStore;
        const mappedDisabledInvestorTypes = disabledInvestorTypes?.map(type =>
            type.replace(/ /g, "-").toLowerCase(),
        );
        if (!mappedDisabledInvestorTypes?.includes(investor) || !fallbackSlug) {
            return;
        }
        navigate(getPathForSlug(fallbackSlug), { replace: true });
    };

    return {
        getPath,
        getPathForSlug,
        getAlternateLanguagePaths,
        redirectIfPageDisabled,
        getHomePath,
    };
};

export default useRouting;
