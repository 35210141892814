import { ValuesCard as Card } from "@2po-dpam/components";
import { ILink } from "@types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { useContextStore, useRouting } from "@hooks";
import { getFixedUrlIfMarketAndLanguageDoesNotExist } from "@utils/url";

type Props = {
    data: {
        image: any;
        internalLink?: ILink;
        externalLink?: string;
    };
    options?: any;
};

const ValuesCard = ({
    data: { image, internalLink, externalLink },
    options,
}: Props) => {
    const [hrefToUse, setHrefToUse] = useState<string | undefined>(undefined);
    const { language, market } = useContextStore();
    const { getPathForSlug } = useRouting();

    useEffect(() => {
        const link = internalLink?.pageLink?.slug
            ? getPathForSlug(internalLink.pageLink.slug)
            : externalLink;

        if (link && language) {
            setHrefToUse(
                getFixedUrlIfMarketAndLanguageDoesNotExist(
                    link,
                    language,
                    market,
                ),
            );
        }
    }, [internalLink, externalLink, language, market]);

    return (
        <Card
            caption={image.caption}
            destination={hrefToUse}
            hideContentMobile={options?.hideContentMobile}
            image={
                <GatsbyImage
                    alt={image.title}
                    image={image?.image?.gatsbyImageData}
                />
            }
            value={image.title}
        />
    );
};

export default ValuesCard;

export const query = graphql`
    fragment ValuesCard on ContentfulTopicValue {
        id
        __typename
        contentful_id
        externalLink
        internalLink {
            ...NavLink
        }
        image {
            ...ImageConstrained
        }
    }
`;
