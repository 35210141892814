import { Modal, Select, Typography } from "@2po-dpam/components";
import { RichText } from "@components";
import {
    useBrowserLanguage,
    useContextStore,
    useCookies,
    useFocusTrap,
    useMarketsLanguages,
    usePopupData,
    useRouting,
} from "@hooks";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as style from "./style.module.scss";

const getFocusableElements = (id: string) => [
    `${id}-select-market-trigger`,
    `${id}-select-language-trigger`,
    `${id}-secondaryButton`,
    `${id}-primaryButton`,
];

type Props = {
    isOpen: boolean;
    id?: string;
    onClose: () => void;
    data: any;
    currentLanguage: string;
};

const MarketLanguagePopup = ({
    id = "MarketLanguagePopup",
    onClose,
    isOpen,
    data,
    currentLanguage,
}: Props) => {
    const { setCookie } = useCookies();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("");
    const [market, setMarket] = useState("");
    const { disclaimerText } = usePopupData(language, market);
    const {
        language: lang,
        market: currentMarket,
        availableLocales,
    } = useContextStore();
    const { getPath, getHomePath } = useRouting();
    const { getLanguage } = useBrowserLanguage();
    const { markets, languages } = useMarketsLanguages(
        data,
        currentLanguage,
        market,
    );

    useFocusTrap(getFocusableElements(id), id, isOpen);

    useEffect(() => {
        if (!isOpen || !lang || !currentMarket) return;
        setMarket(currentMarket);
        setLanguage(lang);
    }, [isOpen, lang, currentMarket]);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    const handleSetPreferences = () => {
        const isMarketPt = market === "pt";
        const marketToUse = isMarketPt ? "be" : market;
        const locale = `${language}-${marketToUse.toUpperCase()}`;
        setCookie("market", marketToUse);
        setCookie("language", language);

        const newPath =
            availableLocales.includes(locale) || !availableLocales.length
                ? getPath({ language: language, market: marketToUse })
                : getHomePath({ language: language, market: marketToUse });
        onClose();

        if (newPath !== location?.pathname) {
            navigate(newPath);
        }
    };

    const handleChangeMarket = (market: string) => {
        setMarket(market);
        setLanguage(getLanguage(market));
    };

    return (
        <Modal
            closable
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            primaryButton={{
                label: t("popup confirm") || "Set preferences",
                onClick: handleSetPreferences,
                disabled: !market || !language,
                tabIndex: 3,
            }}
        >
            <Typography color="main" variant="h4">
                <Trans i18nKey="marketLanguage.title">
                    <strong>Market &amp; Language</strong> preferences
                </Trans>
            </Typography>
            <label
                className={style.label}
                htmlFor={`${id}-select-market-trigger`}
                id={`${id}-select-market-label`}
            >
                <Typography removeMargin variant="para">
                    {t("marketLanguage.label-market")}
                </Typography>
            </label>
            <Select
                className={style.select}
                id={`${id}-select-market`}
                items={markets}
                labelId={`${id}-select-market-label`}
                locale={currentLanguage}
                noBoldStyling
                onChange={handleChangeMarket}
                optionClassName={style.option}
                tabIndex={1}
                value={market}
            />
            <label
                className={style.label}
                htmlFor={`${id}-select-language-trigger`}
                id={`${id}-select-language-label`}
            >
                <Typography removeMargin variant="para">
                    {t("marketLanguage.label-language")}
                </Typography>
            </label>
            <Select
                className={style.select}
                disabled={!market}
                id={`${id}-select-language`}
                items={languages}
                labelId={`${id}-select-language-label`}
                noBoldStyling
                onChange={setLanguage}
                optionClassName={style.option}
                tabIndex={2}
                value={language}
            />
            <div className={style.caption}>
                <RichText textNode={{ text: disclaimerText }} />
            </div>
        </Modal>
    );
};

export default MarketLanguagePopup;
